<template>
  <div class="hello">
     <div class="img-fluid w-100 d-flex">
      <img alt="Uni_logo" src="../assets/uni_logo.jpg">
  </div>
    <hr>

    <div>
        <h3> Lista de Proyectos de Grado </h3>
        </div>
   <v-card>
      <v-card-title>
        
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search2"
          append-icon="mdi-magnify"
          label="Criterio de Busqueda"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="projects"
        :search="search2"
      >
      </v-data-table>

    </v-card>


 </div> 
</template>

<script>
import axios from 'axios';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },

  data() {
    return {
      search2: '',
      projects: [],
      errors: [],
      headers: [
        'Content-Type: application/json', 
       /*  {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        }, */
        { text: "CODIGO", value: "code" },
        { text: "TITULO", value: "title" },
        { text: "AUTOR", value: "author" },
        { text: "TUTOR", value: "tutor" },
        { text: "CARRERA", value: "Career.name"},
        { text: "SEDE", value: "Career.Sede.description" },
        { text: "AÑO", value: "year" },

      ]
    };
  },
  async mounted () {
        try {
            //const res = await axios.get('http://localhost:3000/projects')
            //const res = await axios.get('http://localhost:3000/projects?page=1&limit=2')
            const res = await axios.get('http://190.104.11.190:3001/projects')
            this.projects = res.data;
            console.log(this.projects);
            //this.pages = res.data;
        } catch (error) {
            this.errors.push(error);
        }
    },
  /*   computed: {
        filteredList() {
            return this.projects.filter((project) => {
               return project.title.toLowerCase().match(this.search) ||
               project.author.toLowerCase().match(this.search) || 
               project.Career.Sede.description.toLowerCase().match(this.search)||
               project.Career.name.toLowerCase().match(this.search);     
            });
        }
    } */
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

img {
    height: 150px;
    float: left;
}
table th, table td {
    border: 1px solid #dee2e6;
}
input{
    width: 250px;
}
</style>
